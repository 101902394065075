<template>
    <div class="dd_about">
        <div class="dd_about_content">
            <div class="dd_about_content_video">
                <img src="https://dreamdeck.cn/app/icons/website/banner/2019-12/21/e4a27887-a9d8-4ab8-9d49-2dbd8d4bddcb.jpg" alt="">
                <a href="javascript:;">
                    <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle" @click="checkVisibleType">
                        <VideoPlay />
                    </el-icon>
                    <!-- <PlayCircleOutlined @click="checkVisibleType" :style="{color:'#1cb9c8'}"/> -->
                </a>
            </div>
            <div class="dd_about_content_text">
                <p>北京甲板智慧科技有限公司，是智慧城市户外空间的龙头企业，通过科技与设计的交叉融合，打造甲板系统体系，形成高效的商业闭环。通过对行业的深刻理解和数据的积累引领户外空间需求，用科技重塑户外人居。公司是国家高新技术企业+中关村高新技术企业，已参与百余个知名智慧项目实施，代表案例有AI海淀公园、G-PARK能量公园、通州智慧园林平台等。其中，AI海淀公园是全球首个AI智慧公园。在智慧城市业内流传这样一句话，城市户外数据甲板有，服务提升找甲板。</p>
                <div class="dd_about_content_text_phone">
                    <div class="dd_about_content_text_phone_icon">
                        <!-- <PhoneOutlined :style="{color:'#1cb9c8'}"/> -->
                        <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle">
                            <Phone />
                        </el-icon>
                    </div>
                    <div class="dd_about_content_text_phone_person">
                        <h5>联系电话</h5>
                        <div>
                            <p>合作咨询 :</p>
                            <div>
                                <!-- <p>全国热线电话：400-618-2880</p> -->
                                <p>北京总公司：李女士 18514621168</p>
                                <!-- <p>上海分公司：张洋 先生 18653203596</p> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dd_about_content_text_phone">
                    <div class="dd_about_content_text_phone_icon">
                        <!-- <Location :style="{color:'#1cb9c8'}" /> -->
                        <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle">
                            <Location />
                        </el-icon>
                    </div>
                    <div class="dd_about_content_text_phone_person">
                        <h5>公司地址</h5>
                        <p>北京市石景山古城南街9号院绿地环球文化金融城5号楼21层</p>
                    </div>
                </div>
                <div class="dd_about_content_text_phone">
                    <div class="dd_about_content_text_phone_icon">
                        <!-- <PhoneOutlined :style="{color:'#1cb9c8'}"/> -->
                        <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle">
                            <Discount />
                        </el-icon>
                    </div>
                    <div class="dd_about_content_text_phone_person">
                        <h5>公司公众号</h5>
                        <p>DreamDeck梦想甲板</p>
                        <img src="../assets/gongzhonghao.jpg" alt="">
                    </div>
                </div>
                <div class="dd_about_content_text_phone">
                    <div class="dd_about_content_text_phone_icon">
                        <!-- <User :style="{color:'#1cb9c8'}" /> -->
                        <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle">
                            <User />
                        </el-icon>
                    </div>
                    <div class="dd_about_content_text_phone_person">
                        <h5>公司微信号</h5>
                        <p>甲板智慧科技有限公司</p>
                        <img src="../assets/weixin.jpg" alt="">
                    </div>
                </div>
                <div class="dd_about_content_text_phone">
                    <div class="dd_about_content_text_phone_icon">
                         <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle">
                            <Film />
                        </el-icon>
                    </div>
                    <div class="dd_about_content_text_phone_person">
                        <h5>公司视频号</h5>
                        <p>DreamDeck梦想甲板</p>
                        <img src="../assets/video.png" alt="">
                    </div>
                </div>
                <div class="dd_about_content_text_phone">
                    <div class="dd_about_content_text_phone_icon">
                        <!-- <VideoCamera :style="{color:'#1cb9c8'}"/> -->
                         <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle">
                            <VideoCamera />
                        </el-icon>
                    </div>
                    <div class="dd_about_content_text_phone_person">
                        <h5>公司抖音号</h5>
                        <p>DreamDeck梦想甲板</p>
                        <img src="../assets/dy.jpg" alt="">
                    </div>
                </div>
                <div class="dd_about_content_text_phone">
                    <div class="dd_about_content_text_phone_icon">
                        <!-- <FileJpgOutlined :style="{color:'#1cb9c8'}" /> -->
                        <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle">
                            <FolderAdd />
                        </el-icon>
                    </div>
                    <div class="dd_about_content_text_phone_person">
                        <h5 style="cursor: pointer;" @click="hrefOpen">公司宣传册下载</h5>
                        <h5>【版权所有 尊重原创 侵权必究！】 COPYRIGHT INFRINGEMENT</h5>
                    </div>
                </div>
                <div class="dd_about_content_text_phone">
                    <div class="dd_about_content_text_phone_icon">
                        <!-- <CollectionTag :style="{color:'#1cb9c8'}" /> -->
                        <el-icon :size="26" color="#1cb9c8" style="vertical-align: middle">
                            <CollectionTag />
                        </el-icon>
                    </div>
                    <div class="dd_about_content_text_phone_person">
                        <h5>公司招聘</h5>
                        <p>我们正在寻找的伙伴</p>
                        <Recruit />
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="visibleType"
            title="Tips"
            width="30%"
            :before-close="handleClose"
        >
            <video controls autoplay src="https://www.dreamdeck.cn/app/icons/website/about.mp4"></video>
        </el-dialog>
        <!-- <a-modal v-model:visible="visibleType" title="Basic Modal" destroyOnClose>
            <video controls autoplay src="https://www.dreamdeck.cn/app/icons/website/about.mp4"></video>
            <CloseOutlined @click="close" :style="{color:'#fff'}"/>
        </a-modal> -->
    </div>
</template>
<script>
import Carousel from '@/components/carousel.vue'
import Recruit from '@/components/recruit.vue'
import { VideoPlay, Phone, Location, Discount, User, Film, VideoCamera, FolderAdd, CollectionTag } from '@element-plus/icons-vue';
export default {
    data() {
        return {
            brannerList : { 
              type : '-2',
              status: '0'
            },
            urlList : [],
            visibleType : false
        }
    },
    created(){
    },
    components :{
        Carousel,
        Recruit,
        VideoPlay,
        Phone,
        Location,
        Discount,
        User,
        Film,
        VideoCamera,
        FolderAdd,
        CollectionTag

    },
    methods : {
        hrefOpen(){
            window.open('https://www.dreamdeck.cn/app/icons/website/publicity.pdf')
        },
        handleClose(done) {

            done()
        },
        checkVisibleType(){
            this.visibleType = true
            //console.log(this.visibleType);
        }
    }
}
</script>
