<template>
  <div class="secondBox">
    <div id="echartsBg">
      <div class="introduce">
        <div>{{ indexInfoData.name }}</div>
        <div v-if="isShow" v-html="indexInfoData.subName"></div>
        <!-- <div>全国首个地产景观智能化示范项目-光合公园</div>
        <div>首个碳中和主题公园-北京温榆河未来智谷</div>
        <div>首个老旧社区智能提升改造-昌盛模式</div>
        <div>全国首个区级智慧园林综合管控平台</div>-->
      </div>
      <div class="bgc"></div>
      <div
        id="myEcharts"
        ref="kChart"
        :style="{ width: '50%', height: '90%',position:'absolute',right:'165px',top:'60px'}"
      ></div>
      
      <div class="ddReturn" @click="goHome">
        <img src="https://www.dreamdeck.cn:10443/app/icons/website/back.png" alt />
      </div>
      <div class="ddAll">
        <div class="ddCode">
          <img src="https://www.dreamdeck.cn:10443/app/icons/website/qrcode.png" alt />
        </div>
        <div class="ddBody">
          <img src="https://www.dreamdeck.cn:10443/app/icons/website/ddBody.gif" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import particlesJson from "../util/particles.json";
import * as echarts from "echarts";
import { getIndexInfo } from "@/api/index";
let chart = null;
export default {
  data() {
    return {
      name:'', // 标题
      subName: "", // 副标题
      isShow: true, // 是否展示副标题
      secondList1: [],
      secondBubble: [],
      contentDetails: "",
      secondList: [],
      option: {
        // backgroundColor: "#000000",
        tooltip: {},
        animationDurationUpdate: function(idx) {
          // 越往后的数据延迟越大
          return idx * 100;
        },
        grid : {
          left : '20px',
          right : '20px',
          bottom : '20px',
          top: '20px'
        },
        
        animationEasingUpdate: "bounceIn",
        color: ["#fff", "#fff", "#fff"],
        series: [
          {
            type: "graph",
            layout: "force",
            
            circular : {
              rotateLabel : false
            },
            // edgeLength : 10,
            label: {
              show: true,
              position: "inside",
              fontSize: 20,
              fontFamily: "youshe",
              width: 130,
              color: "#fff"
            },
            lineStyle: {
              color: "rgba(0, 0, 0, 0)"
            },
            data: [],
            links: []
          }
        ]
      },
      indexInfoData : {}
    };
  },
  watch: {
    option: {
      handler(newVal, oldVal) {
        if (chart) {
          if (newVal) {
            chart.setOption(newVal);
          } else {
            chart;
            chart.setOption(oldVal);
          }
        } else {
          this.initEcharts();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getBubble();
    this.initEcharts();
    // 引入背景
    require("particles.js");
    particlesJS("echartsBg", particlesJson);
  },
  methods: {
    
    initEcharts() {
      let that = this;
      chart = echarts.init(document.getElementById("myEcharts"));
      window.addEventListener("resize", function() {
        chart.resize();
      });

      window.onresize = chart.resize / 2;
      chart.clear();
      chart.setOption(this.option, true);
      chart.on("click", function(params) {
        console.log(params, "!!!!!!!!");
        if (params.data.level == 2) {
          // that.secondContent = true;
          window.open(`${window.location.origin}/website/${params.data.id}.html`,'_self')
        }
      });
    },
    // 回到首页
    goHome() {
      this.$router.push("/");
    },

    // 根据一级拿到二级列表
    getBubble() {
      let that = this;
      getIndexInfo(this.$route.query.id).then(
        res => {
          // console.log(res, "获取二级分类");
          this.indexInfoData = res.data;
          this.indexInfoData.subName = this.indexInfoData.subName.replace(/\n/g, "<br>");
          that.option.series[0].force = {
            repulsion: 20,
            edgeLength: document.documentElement.clientWidth < 1100 ? 2 : 15
          },
          that.option.series[0].data = []
          that.option.series[0].data.push({
            name: this.indexInfoData.name,
            id: this.indexInfoData.id,
            // value: "NO.1",
            symbolSize: document.documentElement.clientWidth < 1100 ? 70 : 170,
            x: 0,
            y: 0,
            level : 1,
            
            label : {
              fontSize : document.documentElement.clientWidth < 1100 ? 12 : 20,
            },
            itemStyle: {
              normal: {
                // fontSize : 10,
                borderWidth: 4,
                shadowBlur: 20,
                color: "rgba(0, 0, 0, 0)"
              }
            }
          })

          let newSecondList = this.indexInfoData.parentList
          // [
          //   {
          //     id : 4,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 5,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 6,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 7,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 8,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 9,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 10,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 12,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 13,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 14,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 15,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 16,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 17,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 18,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 19,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 20,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 21,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 22,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 23,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 24,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },

          //   {
          //     id : 25,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          //   {
          //     id : 26,
          //     lecel : 2,
          //     name: "网红打卡装置",
          //   },
          // ]
          for (var i = 0; i < newSecondList.length; i++) {
            that.option.series[0].data.push({
              ...newSecondList[i],
              x: 20,
              y: 70,
              symbolSize: document.documentElement.clientWidth < 1100 ? 30 : 52,
              symbol:
                "image://https://www.dreamdeck.cn:10443/app/icons/website/secondoval.png",
              // draggable: true,
              // force: {
              //   // repulsion: 50,
              //   edgeLength: 300
              // }, 
              level : 2,
              label: {
                color: "#C2C2C3",
                position: "bottom",
                fontSize: document.documentElement.clientWidth < 1100 ? 10 : 16,
                fontFamily: "normal",
                overflow: "normal"
              },
              itemStyle: {
                borderWidth: 4,
                shadowBlur: 20,
                color: "#3970E4"
              }
            });
            that.option.series[0].links.push({
              source: String(newSecondList[i].id),
              target: String(this.$route.query.id)
            });
          }

          
        }
      );
    }
  }
};
</script>

<style lang="less" scoped>

</style>
