<template>
    <div class="dd_recruit_text">
        <header v-for="(item, index) in recruitText" :key="index">
            <p>{{ item.name }}</p>
            <p>【职责描述】{{ item.duty }}</p>
            <p>【任职资格】{{ item.qualification }}</p>
            <p>【微信】{{ item.wx }}</p>
            <br>
        </header>
    </div>
</template>
<script>

export default {
    data() {
        return {
            recruitText: [
                {
                    name : '软件项目经理',
                    duty : '1、负责项目管理工作，对项目的规模、进度、工作量、质量、成本、风险、缺陷等进行控制，保证项目按计划运行，实现公司下达的项目目标；\n2、严格执行公司项目管理的规范，负责整个项目干系人（甲方、上级领导、团队成员等）之间关系的协调，促进项目实施；\n3、制订项目实施计划，组织开发团队，明确各人员的分工，按计划执行并完成开发任务；\n4、负责项目质量管理，对需求一致性、完整性的跟踪管理，对项目中发现的问题和缺陷的解决进行管理和跟踪，组织各阶段评审工作；\n5、指导各类项目文档撰写，包括梳理用户需求、项目原型设计以及实施过程中项目文档编写；\n6、协调系统联调过程中与用户部门和相关业务部门有效沟通；\n7、负责软件系统验收文档的准备和项目过程资料的归档；\n8、配合市场、售前进行需求调研、技术交流、方案宣讲、系统演示等工作',
                    qualification : '1、项目管理、软件开发等相关专业本科及以上学历，3年以上软件开发或项目管理经验；\n2、具备良好的项目文档工作能力，语言文字逻辑强，沟通及表达能力，协调和应变能力强；\n3、熟练使用原型开发工具Axure、Figma等，熟练使用office等办公软件；\n4、熟悉一种编程语言和数据库；\n5、具备项目团队建设管理能力，熟悉软件项目开发和项目管理流程规范；\n6、工作责任心强，执行力高，遵守规范，严于律己，有较强的抗压能力；\n7、有PMP证书、中级项目经理证书者优先；\n8、有软件开发背景的项目管理者优先。',
                    wx : '18330826019'
                },
                {
                    name : 'web开发工程师',
                    duty : '1、承担WEB前端核心模块的设计、开发工作，对代码质量及进度负责。\n2、负责移动端小程序的开发和维护，性能优化。\n3、与UI设计师和后台程序配合，高效高质地完成页面的实现工作。\n4、实现网站页面特效、JS效果；维护及优化前端页面性能，优化交互体验。\n5、参与项目需求分析、技术文档编写。\n6、调研前端主流技术，落地前沿技术，推动业务的发展',
                    qualification : '1、计算机或相关专业本科以上，3年以上前端开发经验、小程序开发经验。\n2、熟练掌握HTML5、CSS3、JavaScript、JQuery、Ajax等技术。\n3、熟练使用Vue等前端开发框架。\n4、熟练掌握BootStarp、Element-UI、Echarts等框架。\n5、有良好的工作及编码习惯，熟练使用git、webpack等工具。\n6、具备性能优化、兼容性处理等调优方案经验。\n7、学习能力强，有较强的分析问题、解决问题能力。\n8、有WEBGIS开发经验优先。',
                    wx : '18330826019'
                },
                {
                    name : '弱电工程师',
                    duty : '1、公园、园区、河道等弱电系统设计、施工图图纸绘制。\n2、编写弱电图纸工程量清单、配合相关单位进行项目审计工作、配合概算员进行概预算编制。\n3、能够进行弱电项目技术答疑，现场解决弱电施工相关问题。',
                    qualification : '1、对弱电监控、广播、停车管理等各个弱电系统的原理及架构熟悉，能独立完成弱电方案、图纸设计及清单。\n2、熟练使用AutoCAD、天正等软件进行智能化图纸制作，具备施工图绘制能力，有设计公司或设计院工作经历者优先。\n3、熟悉弱电系统概预算，会使用广联达软件优先考虑。\n3、有一定的施工经验，具备现场解决弱电问题的能力。\n4、年龄20-35岁，三年以上相关工作经验。\n',
                    wx : '18330826019'
                },
                {
                    name : '景观设计师',
                    duty : '1、智慧公园方案策划，PPT文本编制，方案汇报； \n2、智慧公园工程项目组织协调，与甲方及供应商对接沟通；\n3、辅助各项目策划及落地过程的技术支撑。\n',
                    qualification : '1、两年以上景观、城市规划、建筑设计等城市建设相关行业工作经验；\n2、具备项目策划及方案汇报能力，逻辑清晰，表达能力强；\n3、具备项目协调管理能力，与甲方及供应商对接，沟通能力强；\n4、能熟练使用PPT，CAD，等工具；\n5、对智慧园林有兴趣，愿意尝试新鲜事物，开拓新领域的业务。\n',
                    wx : '18330826019'
                },
                {
                    name : '销售经理',
                    duty : '1.按照部门负责人分工安排，执行项目销售任务，定期向部门负责人汇报；\n2.根据不同的项目按照标准执行销售策略，进行项目前期对接及市场文件汇报、项目初步解决方案汇报，接待业主考察等；\n3.根据产品报价体系完成项目报价并归档；\n4.项目商务谈判及合同拟定，并归档；\n5.负责项目合同签订前各环节跟进与业主沟通；\n6.负责项目的回款相关工作；\n',
                    qualification : '\n1.年龄20-35，男女不限，形象好气质佳；\n2.熟练操作OFFICE等办公软件，熟练驾驶机动车。\n3.大专及以上学历，有工程项目类销售工作经验者优先。',
                    wx : '18330826019'
                },
                {
                    name : '资料员',
                    duty : '1.负责施工资料的收集、编制、整理、立卷、归档.有项目工作经验、独立完成成套施工资料(包括不限于工程资料、技术资料等)；\n2.竣工资料、竣工图的编制、立卷、归档、移交；\n3.严格执行资料工作的要求,加强资料的日常管理和保护工作,定期检查,发现问题及时向分管经理汇报,采取有效措施；\n4.配合完成招投标、采购等事务；\n5.完成领导交办的其它工作\n',
                    qualification : '\n1.年龄20-35，男女不限；\n2.熟练操作OFFICE、CAD、预算等软件。\n3.此岗位需经常出差至项目工地。 \n4.大专及大专以上学历，有园林绿化工程资料、招投标相关工作经验者优先。',
                    wx : '18330826019'
                },
            ],
          query: {
              status: '0'
          }
        }
    },
    created(){
    },
    methods: {
    }
}
</script>
