<template>
  <div class='dd-carousel' v-if="sliders.length !== 0" @mouseleave="stop" @mouseenter="enter" @touchstart='touchStart' @touchmove='touchMove' @touchend='touchEnd'>
    <ul class="carousel-body">
        <li class="carousel-item" v-for="(item,i) in sliders" :key="i" :class="{fade:index===i}">
            <img v-if="item.type == 1" :src="item.url" alt="">
            <video v-else :src="item.url"></video>
        </li>
    </ul>
    <a href="javascript:;" class="carousel-btn prev" @click="toggle(-1)">
        <!-- <LeftCircleOutlined  @click="toggle(-1)"/> -->
        <!-- <i class="iconfont icon-angle-left" @click="toggle(-1)"></i> -->
        <el-icon :size="26" color="#fff" style="vertical-align: middle">
            <ArrowLeft />
        </el-icon>
        
    </a>
    <a href="javascript:;" class="carousel-btn next" @click="toggle(1)">
        <!-- <RightCircleOutlined  @click="toggle(1)"/> -->
        <!-- <i class="iconfont icon-angle-right" @click="toggle(1)"></i> -->
        <el-icon :size="26" color="#fff" style="vertical-align: middle">
            <ArrowRight />
        </el-icon>
    </a>
    <!-- //小圆点 -->
    <div class="carousel-indicator">
      <span v-for="(item,i) in sliders" :key="i" :class="{active:index===i}" @click="index=i"></span>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref, watch } from 'vue'
// import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue';
export default {
    name: 'ddCarousel',
    props: {
        sliders: {
            type: Array,
            default: () => []
        },
        duration: {
            type: Number,
            default: 3000
        },
        autoPlay: {
            type: Boolean,
            default: false
        }
    },
    setup (props) {
        // 默认显示的图片的索引
        const index = ref(0)
        let timer = null
        // 自动播放
        const autoPlayFn = () => {
            timer = setInterval(() => {
                index.value++
                if (index.value >= props.sliders.length) {
                    index.value = 0
                }
            }, props.duration)
        }
        watch(props.sliders, (newVal) => {
            if (newVal.length && props.autoPlay) {
                index.value = 0
                autoPlayFn()
            }
        }, { immediate: true })
        // if (props.autoPlay) {
        //   autoPlayFn()
        // }
        //console.log(timer)
            // 组件销毁时,清空计时器
        onUnmounted(() => {
            clearInterval(timer)
        })
        // 鼠标进入的时候
        const enter = () => {
            if (props.autoPlay) {
                clearInterval(timer)
            }
        }
        // 鼠标离开的时候
        const stop = () => {
            autoPlayFn()
        }
        // 点击了轮播图左边的按钮
        const toggle = step => {
            index.value += step
            if (index.value >= props.sliders.length) {
                index.value = 0
            }
            if (index.value < 0) {
                index.value = props.sliders.length - 1
                // 一共5 然后-1就是最后一张图
            }
        }

        let startX = null;
        const touchStart = function(ev){
            if(ev.touches.length == 1) { //tounches类数组，等于1时表示此时有只有一只手指在触摸屏幕
                startX = ev.touches[0].clientX; // 记录开始位置
                // 清空定时器
                clearInterval(timer)
            }
        }
        let moveX = null;
        const touchMove = function(ev) {
            // console.log(ev.touches[0].clientX);
            moveX = ev.touches[0].clientX;
        }

        const touchEnd = function(ev){
            if (moveX - startX < 0) {
                toggle( 1 )
            }else{
                toggle( -1 )
            }
        }


        return { index, autoPlayFn, enter, stop, toggle, touchStart, touchMove, touchEnd }
    },
    components: {
        ArrowLeft,
        ArrowRight
    },
    created(){
        for (let i = 0; i < this.$props.sliders.length; i++) {
            if (this.$props.sliders[i].url.indexOf('png') !== -1 || this.$props.sliders[i].url.indexOf('jpg') !== -1 || this.$props.sliders[i].url.indexOf('jpeg') !== -1 || this.$props.sliders[i].url.indexOf('JPEG') !== -1 || this.$props.sliders[i].url.indexOf('PNG') !== -1 || this.$props.sliders[i].url.indexOf('JPG') !== -1 || this.$props.sliders[i].url.indexOf('gif') !== -1) {
                this.$props.sliders[i].type = 1
            }
            if (this.$props.sliders[i].url.indexOf('mp4') !== -1) {
                this.$props.sliders[i].type = 2
            }

        }

    },
}
</script>
