<template>
  <div id="homeBox">
    <div id="echartsBg">
      <div id="particles">
        <div class="title">科技重塑人居，改变城市生活</div>
        <div id="sector" ref="kChart"></div>
        <div class="imgGIF">
          <img
            class="imgHead"
            src="https://www.dreamdeck.cn:10443/app/icons/website/DDHead.gif"
            alt
          />
        </div>
        
      </div>
      
    </div>
    <div class="ddAll">
      <div class="ddCode">
        <img src="https://www.dreamdeck.cn:10443/app/icons/website/qrcode.png" alt />
      </div>
      <div class="ddBody">
        <img src="https://www.dreamdeck.cn:10443/app/icons/website/ddBody.gif" alt />
      </div>
    </div>
  </div>
</template>

  <script>
import particlesJson from "../util/particles.json";
let chart = null;
import * as echarts from "echarts";
import { getIndexAll } from "@/api/index";
export default {
  name: "sector",
  data() {
    return {
      symbolsize: null,
      fontSize: null,
      secondSize: null,
      windowWidth: "",
      FirseBubble: [], //一级列表
      secondBubble: [], // 二级
      option: {
        // 图表标题
        animationDurationUpdate: function (idx) {
          // 越往后的数据延迟越大
          return idx * 100;
        },
        animationEasingUpdate: "bounceIn",
        color: ["#fff", "#fff", "#fff"],
        series: [
          {
            type: "graph",
            layout: "circular",
            force: {
              repulsion: 500,
              edgeLength: [10, 20],
              gravity: 0.05,
            },
            zoom: document.documentElement.clientWidth < 1100 ? 0.8 : 0.9,
            scaleLimit: {
              min: 0.6,
            },
            itemStude: {
              color: "#3970E4",
            },
            lineStyle: {
              color: "rgba(0, 0, 0, 0)",
            },
            label: {
              show: true,
              position: "inside",
              formatter: "{b}",
              fontSize: 16,
              fontFamily : 'Source Han Sans CN',
              fontWeight : '600'
            },
            data: [],
          },
          {
            type: "graph",
            layout: "none",
            scaleLimit: {
              min: 0.6,
            },
            lineStyle: {
              color: "rgba(0, 0, 0, 0)",
            },
            itemStude: {
              color: "#3970E4",
            },
            label: {
              show: true,
            },

            data: [],
          },
        ],
      },
      // 二级固定位置  pc端
      secondBubbleList: [
        {
          x: 100,
          y: 200,
        },
        {
          x: 150,
          y: 100,
        },
        {
          x: 150,
          y: 300,
        },
        {
          x: 850,
          y: 100,
        },
        {
          x: 900,
          y: 200,
        },
        {
          x: 850,
          y: 300,
        },
      ],
      // 二级固定位置  移动端
      secondBubbleMoveList: [
        {
          x: 30,
          y: 10,
        },
        {
          x: 60,
          y: 5,
        },
        {
          x: 90,
          y: 10,
        },
        {
          x: 30,
          y: 100,
        },
        {
          x: 60,
          y: 105,
        },
        {
          x: 90,
          y: 100,
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getIndexAll();
    this.windowWidth = document.documentElement.clientWidth;
    this.getSecondBubble();
    
    this.getWindowWidth();


    
    this.initCharts();
    
    // eslint-disable-next-line no-undef
    // 引入背景
    require("particles.js");
    particlesJS("particles", particlesJson);
  },
  watch: {
    option: {
      handler(newVal, oldVal) {
        if (chart) {
          if (newVal) {
            chart.setOption(newVal);
          } else {
            chart.setOption(oldVal);
          }
        } else {
          this.initCharts();
        }
      },
      deep: true,
    },
    windowWidth(val) {
      if (this.windowWidth < 768) {
        this.symbolsize = 70;
        this.fontSize = 12;
        this.secondSize = 10;
      } else {
        this.symbolsize = 100;
        this.fontSize = 18;
        this.secondSize = 16;
      }
      this.initCharts();
    },
  },
  methods: {
    
    getWindowWidth() {
      var that = this;
      // <!--把window.onresize事件挂在到mounted函数上-->
      window.onresize = () => {
        return (() => {
          that.windowWidth = document.documentElement.clientWidth;
        })();
      };
    },
    // 点击二级查看详情
    getContent(id) {
      window.open(`${window.location.origin}/website/${id}.html`,'_self')
    },
    // 点击一级跳到二级
    goSecond(params) {
      console.log(params.data, "99999999999999999");
      this.$router.push(
        `/secondLevel?id=${params.data.id}`
      );
    },
    // 获取一级列表
    getIndexAll() {
      let that = this;
      getIndexAll({ showType: 0, status: 0, type: 1 }).then((res) => {
        console.log(res, "获取一级列表");
        that.FirseBubble =
          res.data.length <= 5 ? res.data : res.data.slice(0, 6);
        console.log(that.FirseBubble, 33333);
        for (var i = 0; i < that.FirseBubble.length; i++) {
          that.option.series[0].data.push({
            name: that.FirseBubble[i].name,
            id: that.FirseBubble[i].id,
            level: 1,
            value: 2373,
            symbolSize: that.symbolsize,
            draggable: false,
            symbol:
              "image://https://www.dreamdeck.cn:10443/app/icons/website/first.png",
            itemStyle: {
              color: "#ffffff",
              fontWeight: "500",
            },
            label: {
              position: "bottom",
              color: "#ffffff",
              fontSize: that.fontSize,
              width: document.documentElement.clientWidth < 1100 ? 80 : 'auto',
              // fontWeight:'500',
              fontFamily: "Source Han Sans CN",
              overflow: "breakAll",
            },
          });
        }
      });
    },

    // 获取二级列表
    getSecondBubble() {
      let that = this;
      getIndexAll({status: 0, type: 2, index: 0 }).then((res) => {
        that.option.series[1].data = [];
        that.secondBubble =
          res.data.length <= 5 ? res.data : res.data.slice(0, 6);
        let list = document.documentElement.clientWidth < 1100 ? that.secondBubbleMoveList :  that.secondBubbleList
        for (var i = 0; i < that.secondBubble.length; i++) {
          that.option.series[1].data.push({
            x: list[i].x,
            y: list[i].y,
            name: that.secondBubble[i].name,
            id: that.secondBubble[i].id,
            level: 2,
            symbolSize: document.documentElement.clientWidth < 1100 ? 20 : 52,
            // draggable: false,
            symbol:
              "image://https://www.dreamdeck.cn:10443/app/icons/website/second.png",
            label: {
              color: "#D3D3D3",
              position: "bottom",
              fontSize: that.secondSize,
              width: document.documentElement.clientWidth < 1100 ? 60 : 'auto',
              
              overflow: "breakAll",
              fontFamily: "Source Han Sans CN4",
            },
          });
        }
      });
    },

    initCharts() {
      let that = this;
      chart = echarts.init(document.getElementById("sector"));
      let graphCanvas = document
        .getElementById("sector")
        .getBoundingClientRect();
      window.addEventListener("resize", function () {
        chart.resize();
      });

      window.onresize = chart.resize;
      chart.clear();
      chart.setOption(that.option, true);
      console.log(that.option, "this.option");
      chart.on("click", function (params) {
        console.log(params);
        if (params.data.level == 1) {
          that.goSecond(params);
        } else if (params.data.level == 2) {
          that.getContent(params.data.id);
        }
      });
    },
  }
};
</script>
