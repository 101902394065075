<template>
    <div class="dd_entire" ref="container">
        <div class="dd_placeholder">
            <Carousel v-if="urlList.length !== 0" :sliders="urlList" :duration="5000" :autoPlay="true"/>
        </div>
        <div class="dd_entire_tabs" ref="inner">
            <div class="dd_entire_tabs_content" v-for="(itemproject, index) in projectList.parentList" :key="index" @click="particulars(itemproject)">
                <div class="dd_entire_tabs_content_img">
                    <img :src="itemproject.imgUrl" alt="">
                </div>
                <h5>{{ itemproject.name }}</h5>
            </div>
            <!-- <div class="dd_entire_tabs_backtop">
                <el-backtop :right="100" target=".dd_entire_tabs" :bottom="100" :visibility-height="1000" />
            </div> -->
            
        </div>
        
    </div>
</template>
<script>
import Carousel from '@/components/carousel.vue' 
import { getIndexInfo } from '@/api/index'
export default {
    data() {
        return {
            activeKey: null,
            params : {
                id : ''
            },
            navList : [],
            project : {
                typeId : '',
                status: '0',
                current : 1,
                size : 8
            },
            bannerList : {
                type : '',
                status: '0'
            },
            projectList : [],
            urlList : [],
            loading: false,
            projectLength : [],
            showType : false
        }
    },
    computed: {
        
    },
    created(){
        this.params.id = this.$route.query.id
        //console.log(this.bannerList);
        this.getIndexInfo()

    },
    mounted(){

    },
    watch: {
        $route: {
            handler: function(val, oldVal){
                this.projectList = []
                this.urlList = []
                this.params.id  = val.query.id
                this.getIndexInfo()
            }
        },
        
    },
    components: {
        Carousel
    },
    methods: {
        scrollTop(){
            // 5 回到顶部
            
        },
        particulars(value){
            window.open(`${window.location.origin}/website/${value.id}.html`,'_self')
        },
        getIndexInfo(){
            getIndexInfo(this.params.id)
                .then(res=>{
                    let data = res.data
                    let imgList = data.imgUrl.split(',')
                    let list = []
                    for (let i = 0; i < imgList.length; i++) {
                        list.push({
                            url : `${window.location.origin}/admin/file/`+imgList[i]
                        })
                        
                    }
                    this.urlList = list
                    for (let i = 0; i < data.parentList.length; i++) {
                        if (data.parentList[i].imgUrl !== '') {
                            data.parentList[i].imgUrl = `${window.location.origin}/admin/file`+data.parentList[i].imgUrl
                        }
                        
                    }
                    this.projectList = data
                })
        },
        

        
    }
}
</script>
